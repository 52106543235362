<template>
  <div id="V2Gallery" ref="v2Gallery">
    <div class="card-list__container card_list_wrap">
      <!-- gallery list area ============ -->
      <div class="card-list">
        <!-- keyword search area ============ -->
        <div class="search-section">
          <section>
            <div class="filter card_filter">
              <section class="filter__wrapper">
                <button
                  class="filter__type"
                  @click="toggleFilterStatus('blockchain')"
                >
                  <span>{{ $t("product.filterBlockchain") }}</span>
                  <img
                    :style="{
                      transform: isFilterVisible('blockchain')
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                    }"
                    src="/img/threespace/icon/chevron.svg"
                  />
                </button>
                <b-collapse :visible="isFilterVisible('blockchain')">
                  <b-form-checkbox-group
                    class="filter__item filter-item"
                    v-model="externalParams.keywordChain"
                    :options="commonCodes['ART_FILTER_BLOCKCHAIN']"
                    @change="onClickSearch"
                    stacked
                  />
                </b-collapse>
              </section>

              <section class="filter__wrapper">
                <button
                  class="filter__type"
                  @click="toggleFilterStatus('arttype')"
                >
                  <span>{{ $t("product.filterArttype") }}</span>
                  <img
                    :style="{
                      transform: isFilterVisible('arttype')
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                    }"
                    src="/img/threespace/icon/chevron.svg"
                  />
                </button>
                <b-collapse :visible="isFilterVisible('arttype')">
                  <b-form-checkbox-group
                    class="filter__item filter-item"
                    v-model="externalParams.keywordFiletype"
                    :options="commonCodes['ART_FILTER_TYPE']"
                    @change="onClickSearch"
                    stacked
                  />
                </b-collapse>
              </section>

              <section class="filter__wrapper">
                <button
                  class="filter__type"
                  @click="toggleFilterStatus('display')"
                >
                  <span>{{ $t("product.filterDisplay") }}</span>
                  <img
                    :style="{
                      transform: isFilterVisible('display')
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                    }"
                    src="/img/threespace/icon/chevron.svg"
                  />
                </button>
                <b-collapse :visible="isFilterVisible('display')">
                  <b-form-checkbox-group
                    class="filter__item filter-item"
                    v-model="externalParams.keywordStatus"
                    :options="statusOptions"
                    @change="onClickSearch"
                    stacked
                  />
                </b-collapse>
              </section>
              <section class="filter__wrapper">
                <button
                  class="filter__type"
                  @click="toggleFilterStatus('paytype')"
                >
                  <span>{{ $t("product.filterPaytype") }}</span>
                  <img
                    :style="{
                      transform: isFilterVisible('paytype')
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                    }"
                    src="/img/threespace/icon/chevron.svg"
                  />
                </button>
                <b-collapse :visible="isFilterVisible('paytype')">
                  <b-form-checkbox-group
                    class="filter__item filter-item pay"
                    v-model="externalParams.keywordPaytype"
                    :options="paytypeOptions"
                    @change="onClickSearch"
                    stacked
                  ></b-form-checkbox-group>
                </b-collapse>
              </section>
            </div>

            <button
              class="mobile-filter-btn"
              @click="visibleMobileFilter = !visibleMobileFilter"
            >
              <img src="/img/threespace/icon/sort.svg" />
            </button>

            <section class="search">
              <div class="search__wrapper">
                <b-button @click="onClickSearch" class="search_btn px-0">
                  <img src="/img/threespace/icon/search-icon.svg" />
                </b-button>
                <b-form-input
                  type="text"
                  placeholder="Search..."
                  autocomplete="off"
                  v-model="externalParams.keywordText"
                  @keypress.enter.prevent="onClickSearch"
                />
                <b-button
                  @click="onClickResetFilterKeyword"
                  v-if="externalParams.keywordText !== ''"
                  class="search_reset_btn px-0"
                >
                  <img src="/img/threespace/icon/drop-x.png" />
                </b-button>
              </div>
              <div class="sort-section">
                <b-button
                  @click.stop="showSortByFilter"
                  class="sort-section__button"
                  :class="{ active: visibleSortBy }"
                >
                  <span>{{
                    Object.keys(sortSelected).length > 0
                      ? sortSelected.text
                      : $t("product.sortBy")
                  }}</span>
                  <img src="/img/threespace/icon/chevron.svg" />
                </b-button>
                <div
                  class="sort-section__list"
                  :class="{ open: visibleSortBy }"
                  v-click-outside:showSortByFilter="false"
                >
                  <template v-for="(item, i) in sortOptions">
                    <div
                      class="sort-item"
                      :key="i"
                      @click="onClickSortByItem(item)"
                      :class="{ active: sortSelected.text === item.text }"
                    >
                      {{ item.text }}
                    </div>
                  </template>
                </div>
              </div>
            </section>
          </section>

          <section
            v-if="
              externalParams.keywordChain.length > 0 ||
              externalParams.keywordFiletype.length > 0 ||
              externalParams.keywordStatus.length > 0 ||
              externalParams.keywordPaytype.length > 0
            "
            class="selected-keyword"
          >
            <div v-if="externalParams.keywordChain.length > 0">
              <span
                >{{ $t("product.filterBlockchain") }}:
                {{ externalParams.keywordChain.join(" / ") }}</span
              >
              <button @click="() => onClickResetKeywordChain()">
                <img src="/img/threespace/icon/close.svg" />
              </button>
            </div>
            <div v-if="externalParams.keywordFiletype.length > 0">
              <span
                >{{ $t("product.filterArttype") }}:
                {{ externalParams.keywordFiletype.join(" / ") }}</span
              >
              <button @click="() => onClickResetKeywordFiletype()">
                <img src="/img/threespace/icon/close.svg" />
              </button>
            </div>
            <div v-if="externalParams.keywordStatus.length > 0">
              <span
                >{{ $t("product.filterDisplay") }}:
                {{ externalParams.keywordStatus.join(" / ") }}</span
              >
              <button @click="() => onClickResetKeywordStatus()">
                <img src="/img/threespace/icon/close.svg" />
              </button>
            </div>
            <div v-if="externalParams.keywordPaytype.length > 0">
              <span
                >{{ $t("product.filterPaytype") }}:
                {{ externalParams.keywordPaytype.join(" / ") }}</span
              >
              <button @click="() => onClickResetKeywordPaytype()">
                <img src="/img/threespace/icon/close.svg" />
              </button>
            </div>
            <button class="reset-all" @click="() => onClickResetFilter()">
              Reset All
            </button>
          </section>
        </div>
        <article class="mobile-filter" v-if="visibleMobileFilter">
          <div>
            <section class="filter-section">
              <p>{{ $t("product.filterBlockchain") }}</p>
              <template
                v-for="(item, i) in commonCodes['ART_FILTER_BLOCKCHAIN']"
              >
                <div
                  class="item"
                  :key="i"
                  @click="() => onClickKeywordChain(item.value)"
                  :class="{
                    active: externalParams.keywordChain.includes(item.value),
                  }"
                >
                  <p>{{ item.text }}</p>
                </div>
              </template>
            </section>
            <section class="filter-section">
              <p>{{ $t("product.filterArttype") }}</p>
              <template v-for="(item, i) in commonCodes['ART_FILTER_TYPE']">
                <div
                  class="item"
                  :key="i"
                  @click="() => onClickKeywordFiletype(item.value)"
                  :class="{
                    active: externalParams.keywordFiletype.includes(item.value),
                  }"
                >
                  <p>{{ item.text }}</p>
                </div>
              </template>
            </section>
            <section class="filter-section">
              <p>{{ $t("product.filterDisplay") }}</p>
              <template v-for="(item, i) in statusOptions">
                <div
                  class="item"
                  :key="i"
                  @click="() => onClickKeywordStatus(item.value)"
                  :class="{
                    active: externalParams.keywordStatus.includes(item.value),
                  }"
                >
                  <p>{{ item.text }}</p>
                </div>
              </template>
            </section>
            <section class="filter-section">
              <p>{{ $t("product.filterPaytype") }}</p>
              <template v-for="(item, i) in paytypeOptions">
                <div
                  class="item"
                  :key="i"
                  @click="() => onClickKeywordPaytype(item.value)"
                  :class="{
                    active: externalParams.keywordPaytype.includes(item.value),
                  }"
                >
                  <p>{{ item.text }}</p>
                </div>
              </template>
            </section>
          </div>
        </article>
        <section class="gallery">
          <div class="total-count">
            <div class="light">{{ $t("product.filterTotal") }}</div>
            {{ galleryProductItems.totalElements }}
            {{ $t("product.countUnit") }}
          </div>
          <transition-group
            name="gallery"
            tag="div"
            class="drops-wrap"
            ref="dropsWrap"
          >
            <template
              v-if="
                producthItems === undefined ||
                producthItems === null ||
                producthItems === 0
              "
            >
              <div class="col-12 mb-5 py-5 text-center" key="0">
                <p class="font-lg">{{ $t("product.noResult") }}</p>
              </div>
            </template>
            <section
              class="position-relative"
              v-for="(item, i) in producthItems"
              :key="i"
              :ref="'cartItem' + item.idx"
            >
              <div
                class="card-list card_list_item cursor-pointer"
                :id="'galleryItem' + item.idx"
                @click="
                  showProductDetailOrOpenSea(
                    item.idx,
                    item.filterDisplayOnly,
                    item
                  )
                "
              >
                <v-lazy
                  :options="{ threshold: 0.5 }"
                  transition="fade-transition"
                >
                  <div
                    class="card_media_box"
                    @contextmenu.prevent.stop
                    :class="{ skeleton_display_none: !isBusy }"
                  >
                    <!-- <CardHoverBox
                      :favoritedCnt="item.favoritedCnt"
                      :pageViewCnt="item.pageViewCnt"
                      :isExternalCollection="false"
                    /> -->
                    <template>
                      <img
                        :src="replaceThumbnailUrl(item)"
                        @error="setAltImg($event, item)"
                      />
                    </template>
                    <b-skeleton-img
                      no-aspect
                      width="100%"
                      height="100%"
                    ></b-skeleton-img>
                  </div>
                </v-lazy>
                <section class="card-info">
                  <!-- <div class="card_title_box" v-if="!isBusy">
                    <div
                      class="artist_name"
                      @click.stop="onClickArtist(item.idxMember)"
                    >
                      <template v-if="item.profileImage === undefined">
                        <CImg
                          class="c-avatar-img mr-2"
                          height="25"
                          src="/img/avatars/profile-gray-person.jpg"
                        />
                      </template>
                      <template v-else>
                        <CImg
                          class="c-avatar-img mr-2"
                          height="25"
                          :src="item.profileImageUrl"
                        />
                      </template>
                      <template
                        v-if="
                          activeLanguage !== undefined &&
                          activeLanguage === 'ko'
                        "
                      >
                        {{ item.artistName || item.artistNameEn }}
                      </template>
                      <template v-else>
                        {{ item.artistNameEn || item.artistName }}
                      </template>
                    </div>
                    <CImg
                      src="/img/threespace/icon/ethereum-black.png"
                      v-if="item.nftType === 'ETHEREUM'"
                      class="chain-logo"
                    />
                    <CImg
                      src="/img/threespace/icon/matic-black.png"
                      v-if="item.nftType === 'POLYGON'"
                      class="chain-logo"
                    />
                    <CImg
                      src="/img/threespace/icon/kaia-black.png"
                      v-else-if="
                        item.nftType === 'KLAYTN' || item.nftType === 'KLIP'
                      "
                      class="chain-logo"
                    />
                    <CImg
                      src="/img/threespace/icon/bitcoin-black.png"
                      v-else-if="item.nftType === 'ORDINALS'"
                      class="chain-logo"
                    />
                  </div>

                  <div class="card_title_box" v-else>
                    <p class="artist_name">
                      <b-skeleton
                        type="avatar"
                        height="30px"
                        width="30px"
                        class="float-left"
                      ></b-skeleton>
                      <b-skeleton
                        class="float-left mt-2 ml-2"
                        width="100px"
                      ></b-skeleton>
                    </p>
                  </div> -->
                  <section class="card-info__header">
                    <p>
                      <template
                        v-if="
                          activeLanguage !== undefined &&
                          activeLanguage === 'ko'
                        "
                      >
                        {{ item.artistName || item.artistNameEn }}
                      </template>
                      <template v-else>
                        {{ item.artistNameEn || item.artistName }}
                      </template>
                    </p>
                    <FavoritedButton
                      :favoritedItem="item"
                      @onClickFavoritedButton="onClickFavoritedButton"
                    />
                  </section>
                  <p class="card-info__title">
                    <template
                      v-if="
                        activeLanguage !== undefined && activeLanguage === 'ko'
                      "
                    >
                      {{ item.productName || item.productNameEn }}
                    </template>
                    <template v-else>
                      {{ item.productNameEn || item.productName }}
                    </template>
                  </p>

                  <div class="card-info__price" v-if="!isBusy">
                    <p
                      v-if="
                        item.status !== 'SOLDOUT' &&
                        item.status !== 'PRIVATESOLD'
                      "
                    >
                      price
                    </p>
                    <p
                      v-else-if="
                        (item.status === 'SOLDOUT' ||
                          item.status === 'PRIVATESOLD') &&
                        item.buyerNickname !== undefined &&
                        item.buyerNickname !== ''
                      "
                    >
                      Owned by
                    </p>
                    <p
                      class="text_wrap"
                      :class="{
                        soldout:
                          item.status === 'SOLDOUT' ||
                          item.status === 'PRIVATESOLD',
                      }"
                    >
                      <template
                        v-if="
                          item.status === 'SOLDOUT' ||
                          item.status === 'PRIVATESOLD'
                        "
                      >
                        {{
                          item.buyerNickname !== undefined &&
                          item.buyerNickname !== ""
                            ? "@" + item.buyerNickname
                            : $t("product.statusSoldOut")
                        }}
                      </template>
                      <template v-else-if="item.status === 'EXHIBIT'">
                        {{ $t("product.statusExhibit") }}
                      </template>
                      <template
                        v-else-if="
                          item.nftType === 'ORDINALS' &&
                          activeLanguage !== undefined &&
                          activeLanguage === 'en'
                        "
                      >
                        {{ getSatoshiToBtc(item.productCoinPrice) }}
                        {{ getCoinCurrencyByNftType(item.nftType) }}
                      </template>
                      <template v-else-if="item.nftType === 'ORDINALS'">
                        {{ getSatoshiToKRW(item.productCoinPrice) }} KRW
                      </template>
                      <template
                        v-else-if="
                          activeLanguage !== undefined &&
                          activeLanguage === 'en' &&
                          isCoinPriceNonZero(item.nftType) &&
                          isAcceptableNftType(item.nftType)
                        "
                      >
                        {{
                          getProductCoinPrice(item.productPrice, item.nftType)
                        }}
                        {{ getCoinCurrencyByNftType(item.nftType) }}
                      </template>
                      <template v-else>
                        {{ item.productPrice | currency }}
                        {{
                          item.productPrice !== undefined &&
                          item.productPrice > 0
                            ? "KRW"
                            : $t("product.statusExhibit")
                        }}
                      </template>
                    </p>
                  </div>
                  <div class="card-info__price" v-else>
                    <span>
                      <b-skeleton width="50%" height="25px"></b-skeleton>
                    </span>
                  </div>
                </section>
              </div>
            </section>
          </transition-group>

          <div
            v-if="!isLast"
            class="loading-wrapper"
            style="text-align: center; padding: 40px 0px 0px 0px"
            ref="infiniteScrollTrigger"
          >
            <div class="loading-spinner">
              <b-spinner variant="primary" label="Loading..."></b-spinner>
            </div>
          </div>

          <!-- <div class="row">
            <div class="pagination-wrap">
              <b-pagination
                v-on:change="onPaging"
                :total-rows="totalRows"
                v-model="currentPage"
                :per-page="perPage"
                hide-ellipsis
                limit="10"
                aria-controls="role-function-list"
                class="float-right gallery_pagination"
              >
                <template #first-text>
                  <img
                    class="page_icon rotate_page_icon"
                    src="/img/threespace/icon/pagination_arrow2_icon.png"
                  />
                </template>
                <template #prev-text>
                  <img
                    class="page_icon rotate_page_icon"
                    src="/img/threespace/icon/pagination_arrow_icon.png"
                  />
                </template>
                <template #next-text>
                  <img
                    class="page_icon"
                    src="/img/threespace/icon/pagination_arrow_icon.png"
                  />
                </template>
                <template #last-text>
                  <img
                    class="page_icon"
                    src="/img/threespace/icon/pagination_arrow2_icon.png"
                  />
                </template>
                <template #page="{ page }">
                  <span :class="{ active_num: page === currentPage }">
                    {{ page }}
                  </span>
                </template>
              </b-pagination>
            </div>
          </div> -->
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createNamespacedHelpers,
  mapActions,
  mapState,
  mapGetters,
} from "vuex";
import FavoritedButton from "./components/common/FavoritedButton.vue";
// import CardHoverBox from "./components/common/CardHoverBox.vue";
import coinUtils from "../../mixins/CoinUtils";
const codeHelper = createNamespacedHelpers("code");
const galleryHelper = createNamespacedHelpers("gallery");
const coinHelper = createNamespacedHelpers("coin");

export default {
  components: {
    FavoritedButton,
    // CardHoverBox,
  },
  name: "Drops",
  metaInfo() {
    return {
      title: this.$t("meta.drops.title") + " | 3space Art",
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.$t("meta.drops.title") + " | 3space Art",
        },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.drops.description"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.drops.title") + " | 3space Art",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.drops.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.origin + this.$route.path,
        },
      ],
    };
  },
  data: function () {
    return {
      visibleFilterStatus: "",
      visibleFilterPopup: false,
      visibleSortBy: false,
      visibleBlockchain: false,
      visibleArttype: false,
      visibleDisplay: false,
      visiblePaytype: false,
      visibleMobileFilter: false,
      isBusy: false,
      perPage: 30,
      isLast: false,
      totalRows: 0,
      currentPage: 1,
      sort: [
        { id: "displayDatetime", desc: "desc" },
        { id: "idx", desc: "desc" },
      ],
      sortSelected: {},
      sortOptions: [
        {
          value: { id: "displayDatetime", desc: "desc" },
          text: this.$t("product.sortByNewest"),
        },
        {
          value: { id: "orderPayment.orderDate", desc: "desc" },
          text: this.$t("product.sortByRecentlySold"),
        },
        {
          value: { id: "productPrice", desc: "desc" },
          text: this.$t("product.sortByHighestPrice"),
        },
        {
          value: { id: "productPrice", asc: "asc" },
          text: this.$t("product.sortByLowestPrice"),
        },
      ],
      externalParams: {
        keywordCluster: [],
        keywordChain: [],
        keywordFiletype: [],
        keywordStatus: [],
        keywordPaytype: [],
        keywordText: "",
        keywordWithoutOrdinals: true,
      },
      statusOptions: [
        { value: "FORSALE", text: this.$t("product.statusForSaleCard") },
        { value: "SOLDOUT", text: this.$t("product.statusSoldOut") },
        { value: "EXHIBIT", text: this.$t("product.statusExhibit") },
      ],
      paytypeOptions: [
        { value: "TRANSFER", text: this.$t("product.paytypeKRW") },
        { value: "ETH", text: this.$t("product.paytypeETH") },
        { value: "KLAY", text: this.$t("product.paytypeKLAY") },
        { value: "MATIC", text: this.$t("product.paytypeMATIC") },
        { value: "SSX", text: this.$t("product.paytypeSSX") },
        // { value: "BTC", text: this.$t("product.paytypeBTC") }
      ],
      isShowFilter: false,
      observer: null,
      isLoading: false,
      producthItems: [],
    };
  },
  mixins: [coinUtils],
  computed: {
    ...codeHelper.mapState(["commonCodes"]),
    ...galleryHelper.mapState([
      "galleryProductItems",
      "galleryProductItemsContent",
      "previousParams",
    ]),
    ...mapState("coin", ["eth", "klay", "matic", "btc"]),
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      },
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
    isMobile: {
      get: function () {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      },
      set: function () {
        // isMobile 상태에 따라서 변경
      },
    },
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getTickers();
      this.resetGalleryProducts();
      this.requestChildCodes(["ART_FILTER_BLOCKCHAIN", "ART_FILTER_TYPE"]);
      this.requestGalleryProductItems();
      this.setupInfiniteScroll();
    });
  },
  updated() {
    this.setupInfiniteScroll();
  },
  beforeDestroy() {
    // Observer 정리
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  beforeMount() {
    // previousParams Set
    if (this.$route.params.linkType === undefined) {
      if (Object.keys(this.previousParams).length > 0) {
        this.externalParams = this.previousParams.externalParams;
        this.currentPage = this.previousParams.currentPage;
        this.sort = this.previousParams.sort;
        this.sortSelected = this.previousParams.sortSelected;
        this.totalRows = this.previousParams.totalRows;
      }
    } else {
      this.setGalleryPreviousParams({});
    }
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        // create event
        el.eventClickOutside = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.arg](binding.value);
          }
        };
        // event binding
        document.body.addEventListener("click", el.eventClickOutside);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.eventClickOutside);
      },
    },
  },
  methods: {
    ...codeHelper.mapActions(["getChildMultiCodes"]),
    ...galleryHelper.mapActions([
      "getGalleryProductsDrops",
      "setGalleryPreviousParams",
    ]),
    ...galleryHelper.mapMutations(["resetGalleryProducts"]),
    ...coinHelper.mapActions(["getTickers"]),
    ...mapActions("favorited", [
      "addFavorited",
      "deleteFavorited",
      "toggleFavorited",
    ]),
    ...mapActions("auth", ["verifyTokenCallback"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    setAltImg(event, item) {
      if (!item.error) {
        event.target.src = this.replaceDisplayCardUrl(item);
      }
      item.error = true;
    },
    requestChildCodes(codes) {
      this.getChildMultiCodes(codes).then();
    },
    requestGalleryProductItems() {
      this.isBusy = true;
      this.getGalleryProductsDrops(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
        .then((data) => {
          this.producthItems.push(...data.content);
          this.isLast = data.last;
          this.totalRows = this.galleryProductItems.totalElements;
          if (Object.keys(this.previousParams).length > 0) {
            // 클릭한 elements로 이동
            const el = document.getElementById(this.previousParams.clickElId);
            if (el) {
              var headerOffset = 120;
              var elementPosition = el.getBoundingClientRect().top;
              var offsetPosition =
                elementPosition + window.pageYOffset - headerOffset;
              window.scrollTo({
                top: offsetPosition,
                behavior: "auto",
              });
            }
          }
          setTimeout(() => {
            this.isBusy = false;
            this.setGalleryPreviousParams({});
          }, 2000);
        })
        .catch((error) => {
          this.isBusy = false;
          this.setGalleryPreviousParams({});
          this.$log.error(error);
        });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams,
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }

      return params;
    },
    onClickViewMore() {
      if (this.isLast !== true) {
        this.requestGalleryProductItems();
        this.getTickers();
      }
    },
    onClickSearch() {
      this.isLast = true;
      this.currentPage = 1;
      this.producthItems = [];
      this.requestGalleryProductItems();
    },
    showProductDetail(idx) {
      this.$router.push("/art/" + idx);
    },
    showProductDetailOrOpenSea(idx, filterDisplayOnly, item) {
      if (!filterDisplayOnly) {
        let previousParams = {
          externalParams: this.externalParams,
          currentPage: this.currentPage,
          sort: this.sort,
          sortSelected: this.sortSelected,
          clickElId: "galleryItem" + idx,
          totalRows: this.galleryProductItems.totalElements,
        };
        this.setGalleryPreviousParams(previousParams);
        this.$router.push("/art/" + idx);
      } else {
        if (
          item.nftType === "KLIP" &&
          item.klipNftTokenId !== undefined &&
          item.klipNftTokenId !== null &&
          item.klipNftTokenId !== ""
        ) {
          // TODO 컨트랙주소 고정
          const url =
            "https://opensea.io/assets/klaytn/0x2abec25064cbcd8d3c298903098840f95a432073/" +
            item.klipNftTokenId;
          window.open(url, "_blank");
        }
      }
    },
    onClickArtist(idxMember) {
      let idxEncode = window.btoa(idxMember);
      this.$router.push("/collections/" + idxEncode);
    },
    isProductFileTypeImage(_productFileType) {
      if (
        _productFileType !== undefined &&
        _productFileType !== null &&
        _productFileType.startsWith("image")
      ) {
        return true;
      } else {
        return false;
      }
    },
    replaceThumbnailUrl(item) {
      const w = "400";
      const h = "463";
      const thumbnailUrl = `https://collection.3space.art/${process.env.NODE_ENV}/${item.idx}.jpg`;
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return `${thumbnailUrl}?src=${item.displayPosterUrlThreespace}&w=${w}&h=${h}`; // 3space S3 URL
      } else {
        if (
          item.displayPosterUrl !== undefined &&
          item.displayPosterUrl.startsWith("https://ipfs.io/")
        ) {
          item.displayPosterUrl = item.displayPosterUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return `${thumbnailUrl}?src=${item.displayPosterUrl}&w=${w}&h=${h}`;
      }
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayPosterUrl !== undefined &&
          item.displayPosterUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayPosterUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayCardUrl(item) {
      if (
        item.displayCardFile !== undefined &&
        item.displayCardFile !== null &&
        item.displayCardFile !== "" &&
        item.displayCardFile === "THREESPACE"
      ) {
        return item.displayCardUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayCardUrl !== undefined &&
          item.displayCardUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayCardUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayCardUrl;
      }
    },
    showFilterModal(value) {
      if (typeof value === "boolean") this.isShowFilter = value;
      else {
        this.isShowFilter = !this.isShowFilter;
      }
    },
    showSortByFilter(value) {
      if (typeof value === "boolean") this.visibleSortBy = value;
      else {
        this.visibleSortBy = !this.visibleSortBy;
      }
    },
    onClickResetFilter() {
      this.externalParams.keywordChain = [];
      this.externalParams.keywordFiletype = [];
      this.externalParams.keywordStatus = [];
      this.externalParams.keywordPaytype = [];
      if (this.isMobile) this.visibleFilterPopup = false;
      this.onClickSearch();
    },
    onClickResetKeywordChain() {
      this.externalParams.keywordChain = [];
      this.onClickSearch();
    },
    onClickResetKeywordFiletype() {
      this.externalParams.keywordFiletype = [];
      this.onClickSearch();
    },
    onClickResetKeywordStatus() {
      this.externalParams.keywordStatus = [];
      this.onClickSearch();
    },
    onClickResetKeywordPaytype() {
      this.externalParams.keywordPaytype = [];
      this.onClickSearch();
    },
    onClickResetFilterKeyword() {
      this.externalParams.keywordText = "";
      this.onClickSearch();
    },
    onClickSortByItem(item) {
      this.sort[0] = item.value;
      this.visibleSortBy = false;
      this.currentPage = 1;
      this.sortSelected = item;
      this.resetProductItems();
      this.requestGalleryProductItems();
    },
    onPaging(page) {
      window.scrollTo(0, 0);
      this.currentPage = page;
      this.requestGalleryProductItems();
    },
    onClickFavoritedButton(favoritedItem) {
      if (this.isAuthenticated) {
        this.verifyTokenCallback().then(() => {
          if (
            this.me === undefined ||
            this.me === null ||
            this.me.idx === undefined ||
            this.me.idx === null ||
            this.me.idx === ""
          ) {
            // 로그인
            this.hasKlaytnAddr = false;
            this.$store.commit("setCommonAlertModalInfo", {
              msgHtml: this.$t("validation.requiredLogin"),
              show: true,
              fontSize: "sm",
            });
          } else {
            // 로그인 되어있음
            if (favoritedItem.favorited) {
              // true이면 좋아요 헤재
              this.toggleFavorited(favoritedItem);
              this.deleteFavorited({ idxProduct: favoritedItem.idx });
            } else {
              // false이면 좋아요
              this.toggleFavorited(favoritedItem);
              this.addFavorited({ idxProduct: favoritedItem.idx });
            }
          }
        });
      } else {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("validation.requiredLogin"),
          show: true,
          fontSize: "sm",
        });
      }
    },
    onClickKeywordChain(value) {
      if (this.externalParams.keywordChain.includes(value)) {
        this.externalParams.keywordChain =
          this.externalParams.keywordChain.filter((v) => v !== value);
      } else {
        this.externalParams.keywordChain.push(value);
      }
      this.onClickSearch();
    },
    onClickKeywordFiletype(value) {
      if (this.externalParams.keywordFiletype.includes(value)) {
        this.externalParams.keywordFiletype =
          this.externalParams.keywordFiletype.filter((v) => v !== value);
      } else {
        this.externalParams.keywordFiletype.push(value);
      }
      this.onClickSearch();
    },
    onClickKeywordStatus(value) {
      if (this.externalParams.keywordStatus.includes(value)) {
        this.externalParams.keywordStatus =
          this.externalParams.keywordStatus.filter((v) => v !== value);
      } else {
        this.externalParams.keywordStatus.push(value);
      }
      this.onClickSearch();
    },
    onClickKeywordPaytype(value) {
      if (this.externalParams.keywordPaytype.includes(value)) {
        this.externalParams.keywordPaytype =
          this.externalParams.keywordPaytype.filter((v) => v !== value);
      } else {
        this.externalParams.keywordPaytype.push(value);
      }
      this.onClickSearch();
    },
    toggleFilterStatus(type) {
      this.visibleFilterStatus = this.visibleFilterStatus === type ? "" : type;
    },
    isFilterVisible(type) {
      return this.visibleFilterStatus === type;
    },
    resetProductItems() {
      this.producthItems = [];
      this.isLast = false;
      this.totalRows = 0;
    },
    setupInfiniteScroll() {
      this.observer = new IntersectionObserver(
        (entries) => {
          const target = entries[0];
          if (target.isIntersecting && !this.isLoading && !this.isLast && !this.isBusy) {
            this.loadMoreItems();
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        }
      );
      if (this.$refs.infiniteScrollTrigger) {
        this.observer.observe(this.$refs.infiniteScrollTrigger);
      }
    },

    loadMoreItems() {
      if (this.isLoading || this.isLast) return;

      this.isLoading = true;
      this.currentPage += 1;

      this.getGalleryProductsDrops(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
        .then((data) => {
          this.producthItems.push(...data.content);
          this.isLast = data.last;
          this.totalRows = this.galleryProductItems.totalElements;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$log.error(error);
        });
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>

<style scoped>
.v-icon.v-icon::after {
  background-color: rgba(0, 0, 0, 0) !important;
}

.gallery-item {
  display: inline-block;
  margin-right: 10px;
}
.gallery-enter-active,
.gallery-leave-active {
  transition: all 1s;
}
.gallery-enter,
.gallery-leave-to {
  opacity: 0;
}
</style>
